import type { ReactNode } from "react";

type Props = {
    edition: number;
    screenshotUrl: string;
};

const WebsiteCard = ({ edition, screenshotUrl }: Props): ReactNode => {
    return (
        <div className="card">
            <div className="card-header">Furvester {edition}</div>
            <img
                src={screenshotUrl}
                alt={`Furvester ${edition} website`}
                className="card-img-bottom"
            />
            <div className="card-body">
                <a
                    href={`/websites/fv${edition}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary stretched-link"
                >
                    Open Archive
                </a>
            </div>
        </div>
    );
};

export default WebsiteCard;
