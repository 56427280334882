import Layout from "@/components/Layout/index.ts";
import WebsiteCard from "@/components/WebsiteCard/index.ts";
import fv1WebsiteScreenshot from "@/images/screenshots/websites/fv1.jpg";
import fv2WebsiteScreenshot from "@/images/screenshots/websites/fv2.jpg";
import fv3WebsiteScreenshot from "@/images/screenshots/websites/fv3.jpg";
import fv4WebsiteScreenshot from "@/images/screenshots/websites/fv4.jpg";
import fv5WebsiteScreenshot from "@/images/screenshots/websites/fv5.jpg";
import "@/styles/main.scss";
import type { ReactNode } from "react";

const App = (): ReactNode => {
    const colClassNames = "col-xs-12 col-lg-6 col-xxl-4 mb-4";

    return (
        <Layout>
            <div className="container">
                <p>Welcome to the Furvester Archive!</p>
                <p>
                    Here you can find archived versions of our previous websites. Simply click on
                    one of the screenshots to open one up!
                </p>

                <div className="row">
                    <div className={colClassNames}>
                        <WebsiteCard edition={5} screenshotUrl={fv5WebsiteScreenshot} />
                    </div>
                    <div className={colClassNames}>
                        <WebsiteCard edition={4} screenshotUrl={fv4WebsiteScreenshot} />
                    </div>
                    <div className={colClassNames}>
                        <WebsiteCard edition={3} screenshotUrl={fv3WebsiteScreenshot} />
                    </div>
                    <div className={colClassNames}>
                        <WebsiteCard edition={2} screenshotUrl={fv2WebsiteScreenshot} />
                    </div>
                    <div className={colClassNames}>
                        <WebsiteCard edition={1} screenshotUrl={fv1WebsiteScreenshot} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default App;
