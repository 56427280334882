import logo from "@/images/logo-short.svg";
import type { ReactNode } from "react";
import { Head } from "vite-react-ssg/single-page";

type Props = {
    children: ReactNode;
};

const Layout = ({ children }: Props): ReactNode => {
    return (
        <>
            <Head>
                <title>Furvester Archive</title>
            </Head>
            <div className="navbar navbar-dark bg-dark navbar-expand-lg fixed-top">
                <div className="container-fluid">
                    <a href="/" className="navbar-brand">
                        <img
                            src={logo}
                            height="36"
                            className="d-inline-block align-middle me-2"
                            alt="Furvester"
                        />
                        Archive
                    </a>
                </div>
            </div>
            <main>{children}</main>
        </>
    );
};

export default Layout;
